import { graphql } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import React from "react"
import { Hero, Layout, Seo } from "../../components"
import { stripTags } from "../../lib/utils"

const MykaPage = ({ data, pageContext }) => {
  const lang = data?.PageDetails?.edges[0]?.node?.fmmCore?.languageCode
  const slug = data?.PageDetails?.edges[0]?.node?.fmmCore?.frontendSlug
  const copyrightMessage = stripTags(
    data?.FooterCopyright?.edges[0]?.node?.content
  )

  return (
    <Layout
      lang={lang}
      languages={data?.Languages?.edges}
      copyrightMessage={copyrightMessage}
      slug={slug}
    >
      <Seo
        title={data?.PageDetails?.edges[0]?.node?.fmmCore?.seoTitle}
        description={data?.PageDetails?.edges[0]?.node?.fmmCore?.seoDescription}
        lang={lang}
      />

      <Hero
        title={data?.PageDetails?.edges[0]?.node?.hero?.title}
        intro={data?.PageDetails?.edges[0]?.node?.hero?.intro}
        linkTitle={data?.PageDetails?.edges[0]?.node?.hero?.linkLabel}
        linkUrl={data?.PageDetails?.edges[0]?.node?.hero?.linkUrl}
        imageUrl={data?.PageDetails?.edges[0]?.node?.hero?.image?.sourceUrl}
        slug={data?.PageDetails?.edges[0]?.node?.fmmCore?.frontendSlug?.substring(
          1
        )}
        pageContext={pageContext}
        lang={lang}
      />

      <section className="page-section">
        <section className="container">
          <section className="row">
            <section className="myka-img col-12">
                <img
                  src={data?.PageDetails?.edges[0]?.node?.featuredImage?.node?.sourceUrl}
                />
            </section>
            <section className="col-12">
              <section
                className="page-content"
                dangerouslySetInnerHTML={{
                  __html: data?.PageDetails?.edges[0]?.node?.content,
                }}
              />
                <section>
                  {new Date(data?.PageDetails?.edges[0]?.node?.modified).toLocaleDateString()}
                </section>
            </section>
          </section>
        </section>
      </section>
    </Layout>
  )
}

export default MykaPage

export const query = graphql`
  query ($frontend_slug: String!, $language_code: String!) {
    PageDetails: allWpPage(
      filter: {
        fmmCore: {
          languageCode: { eq: $language_code }
          frontendSlug: { eq: $frontend_slug }
        }
      }
    ) {
      edges {
        node {
          id
          content
          modified
          featuredImage {
            node {
                sourceUrl
              localFile {
                childImageSharp {
                  fluid(maxWidth: 900, maxHeight: 900, quality: 100, fit: COVER) {
                    ...GatsbyImageSharpFluid
                    src
                  }
                }
              }
            }
          }
          fmmCore {
            languageCode
            frontendSlug
            seoDescription
            seoTitle
          }
          hero {
            title
            linkUrl
            linkLabel
            intro
            image {
              sourceUrl
            }
          }
        }
      }
    }

    Languages: allWpPage(
      filter: { fmmCore: { frontendSlug: { eq: $frontend_slug } } }
    ) {
      edges {
        node {
          id
          fmmCore {
            languageCode
            frontendSlug
          }
        }
      }
    }

    Hero: allWpGenericBlock(
      filter: {
        fmmCore: {
          languageCode: { eq: $language_code }
          frontendSlug: { eq: $frontend_slug }
        }
        generic_block_data: { type: { eq: "Hero" } }
      }
    ) {
      edges {
        node {
          title
          content
          fmmCore {
            frontendSlug
            languageCode
            seoDescription
            seoTitle
          }
          generic_block_data {
            linkLabel
            priorityOrder
            linkTo {
              target
              title
              url
            }
            image {
              sourceUrl
              srcSet
            }
          }
        }
      }
    }

    FooterCopyright: allWpGenericBlock(
      filter: {
        fmmCore: { languageCode: { eq: $language_code } }
        generic_block_data: { type: { eq: "FooterCopyright" } }
      }
    ) {
      edges {
        node {
          title
          content
        }
      }
    }
  }
`